import React, { useEffect, useState } from "react";
import Getquote from "../pages/Getquote";

import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  {
    /* useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);*/
  }
  return (
    <>
      <section className="w3l-top-menu-4">
        <header className="top-menu4">
          <div className="container">
            <div className="d-grid grid-header">
              <div className=" ">
                <Link to="/Home"><img
                  src="assets/images/logo.png"
                  style={{ height: "100px" }}
                ></img></Link>
              </div>
              <div className="address">
                <ul>
                  <li>
                    <h6 className="address-txt">Email us at</h6>
                  </li>
                  <li>
                    {" "}
                    <Link to="mailto:mail@estate.com">{companyemail}</Link>{" "}
                  </li>
                </ul>
              </div>
              <div className="address">
                <ul>
                  <li>
                    <h6 className="address-txt">Call us today</h6>
                  </li>
                  <li>
                    <Link to="tel:+404 11-22-89"> {companynumber}</Link>
                  </li>
                </ul>
              </div>
              <div className="top-menu4-left d-grid align-items-menu4">
                <div className="nav-item" title="Search">
                  <a href="#search" className="btn search-search">
                    Request A Quote{" "}
                  </a>
                </div>
              </div>
            </div>
            {/* search popup */}
            <div id="search" className="pop-overlay">
              <div className="popup">
                <h3>Request A Quote</h3>
                <Getquote />
              </div>
            </div>
            {/* /search popup */}
          </div>
        </header>
      </section>
      <section className=" w3l-header-4 header-sticky">
        <header className="absolute-top">
          <div className="container">
            <div className="nav-three-columns">
              <nav className="nav-logo">
                <h1></h1>
              </nav>
              <div className="nav-mid navbar-mid">
                <ul>
                  <li className="nav-item">
                    <Link className="nav-link" to="/Home">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/About">
                      Company Profile
                    </Link>
                  </li>
                  <li class="dropdown">
    <Link class="nav-link dropbtn">Services We Provide</Link>
    <div class="dropdown-content">
      <Link to="/Sea" style={{color:"black"}}>Sea Freight</Link>
      <Link to="/Ground" style={{color:"black"}}>Ground Freight</Link>
      <Link to="/Air" style={{color:"black"}}>Air Freight</Link>
      <Link to="/Customs" style={{color:"black"}}>Customs Clearance</Link>
      <Link to="/Supply" style={{color:"black"}}>Supply Chain Solutions</Link>
      <Link to="/Project" style={{color:"black"}}>Project Cargo</Link>      
    </div>
  </li>
                  <li className="nav-item ">
                    <Link className="nav-link" to="/Contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              {/*overlay-menu*/}
              <div className="fullwidth-overlay-menu">
                <input type="checkbox" id="view" />
                <div className="top-menu4-icon">
                  <label htmlFor="view" className="topmenu4-expand">
                    <div className="hamburger2">
                      <div />
                      <div />
                      <div />
                    </div>
                  </label>
                </div>
                <div className="expanded-menu expanded-menu-effect">
                  <label htmlFor="view" className="close" title="Close">
                    ×
                  </label>
                  <nav className="menu-side-over-left">
                    <ul>
                    <li className="nav-item">
                    <Link className="nav-link" to="/Home">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/About">
                      Company Profile
                    </Link>
                  </li>
                  <li class="dropdown">
    <Link class="nav-link dropbtn">Services We Provide</Link>
    <div class="dropdown-content">
      <Link to="/Sea" style={{color:"black"}}>Sea Freight</Link>
      <Link to="/Ground" style={{color:"black"}}>Ground Freight</Link>
      <Link to="/Air" style={{color:"black"}}>Air Freight</Link>
      <Link to="/Customs" style={{color:"black"}}>Customs Clearance</Link>
      <Link to="/Supply" style={{color:"black"}}>Supply Chain Solutions</Link>
      <Link to="/Project" style={{color:"black"}}>Project Cargo</Link>      
    </div>
  </li>
                  <li className="nav-item ">
                    <Link className="nav-link" to="/Contact">
                      Contact
                    </Link>
                  </li>
                    </ul>
                  </nav>
                </div>
              </div>
              {/*/overlay-menu*/}
            </div>
          </div>
        </header>
      </section>

      <Outlet />
    </>
  );
};

export default Header;
