import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />

      <section className="w3l-hero-headers-9 ">
        <div
          className="slide header11 w3-banner jarallax"
          data-selector="header11"
        >
          <video
            className="img-fluid"
            autoPlay
            muted
            loop
            style={{ zIndex: -1 }}
          >
            <source src="assets/images/video.mp4" type="video/mp4" />
          </video>
          <div className="container">
            <div className="banner-text ">
              <h5 style={{ color: "white" }}>We Are {companyname}</h5>
              <h2 style={{ color: "#00a0e3" }}>
                Empowering Your Freight, Empowering Your Business.
              </h2>

              <Link to="/About" className="btn logo-button top-margin">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-teams-15">
        <div className="team-single-main ">
          <div className="container">
            <div className=" grid grid-column-2 row">
              <div
                className="column1 image-back col-lg-6 header11"
                data-selector="header11"
              >
                <img
                  src="assets/images/4.jpg"
                  alt=""
                  className="img-responsive"
                />
              </div>
              <div className="column2 image-text col-lg-6">
                <h3 className="team-head ">Who We Are..</h3>
                <p className="para  text ">
                  As a dedicated freight company, {companyname} is driven by a
                  passion for excellence in cargo transportation. We specialize
                  in delivering reliable and efficient freight forwarding
                  services, catering to a diverse range of industries and
                  clients.
                  <br />
                  With our extensive network and experienced team, we have the
                  expertise to handle various types of cargo, from small
                  packages to oversized and specialized shipments. Our
                  commitment to customer satisfaction means that we go the extra
                  mile to ensure that each consignment reaches its destination
                  on time and in pristine condition.
                  <br />{" "}
                </p>
                <Link to="/About" className="action-button btn mt-4">
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-covers-18">
        <div className="covers-main ">
          <div className="container">
            <div className="main-titles-head text-center">
              <h3 className="header-name ">Services We Provide</h3>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="single-project">
                  <img
                    src="assets/images/sea.png"
                    alt=""
                    className="img-responsive"
                  />
                  {/* Box */}
                  <Link to="/Sea">
                    <div className="box">
                      <div className="box-content">
                        <div className="box-text">
                          <h2> Sea Freight</h2>
                          <p>
                            {" "}
                            At {companyname}, we understand that every ocean
                            freight requirement is unique, and that's why we
                            tailor our services to cater to your specific needs.
                            
                            Our Full Container Load (FCL) and Less Than
                            Container Load (LCL) services ensure that your
                            shipments are handled with precision and efficiency,
                            whether they require full container capacity or are
                            consolidated with other cargo.
                          </p>
                        </div>
                      </div>
                      {/*End box-content */}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="single-project">
                  <img
                    src="assets/images/road.webp"
                    alt=""
                    className="img-responsive"
                  />
                  {/* Box */}
                  <Link to="/Ground">
                    <div className="box">
                      <div className="box-content">
                        <div className="box-text">
                          <h2> Ground Freight</h2>
                          <p>
                            At our company, we understand that your specific
                            requirements are unique and essential to your
                            business. Our top priority is to ensure that we meet
                            those requirements precisely by providing the right
                            transportation solutions through our extensive
                            network.
                          </p>
                        </div>
                      </div>
                      {/*End box-content */}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="single-project">
                  <img
                    src="assets/images/air.jpg"
                    alt=""
                    className="img-responsive"
                  />
                  {/* Box */}
                  <Link to="/Air">
                    <div className="box">
                      <div className="box-content">
                        <div className="box-text">
                          <h2> Air Freight</h2>
                          <p>
                            {" "}
                            {companyname} is dedicated to ensuring the swift and
                            reliable delivery of your time-sensitive cargo to
                            its intended destination, maintaining a reputation
                            for punctuality and efficiency that our clients can
                            depend on.
                          </p>
                        </div>
                      </div>
                      {/*End box-content */}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="single-project">
                  <img
                    src="assets/images/customs.jpg"
                    alt=""
                    className="img-responsive"
                  />
                  {/* Box */}
                  <Link to="/Customs">
                    <div className="box">
                      <div className="box-content">
                        <div className="box-text">
                          <h2>Customs Clearance</h2>
                          <p>
                            {" "}
                            At our company, customs clearance is a fundamental
                            aspect of our operations, and we take pride in our
                            expertise in handling all types of documentation
                            necessary to ensure compliance with customs
                            regulations.
                          </p>
                        </div>
                      </div>
                      {/*End box-content */}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="single-project">
                  <img
                    src="assets/images/supply.jpeg"
                    alt=""
                    className="img-responsive"
                  />
                  {/* Box */}
                  <Link to="/Supply">
                    <div className="box">
                      <div className="box-content">
                        <div className="box-text">
                          <h2> Supply Chain Solutions</h2>
                          <p>
                            {" "}
                            At {companyname}, we pride ourselves on crafting
                            tailor-made supply chain solutions that precisely
                            match our clients' unique and stringent demands. Our
                            expertise lies in creating seamless connections that
                            bridge the gap between global suppliers and end
                            consumers, ensuring a smooth and efficient flow of
                            goods throughout the supply chain.
                          </p>
                        </div>
                      </div>
                      {/*End box-content */}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="single-project">
                  <img
                    src="assets/images/project.jpg"
                    alt=""
                    className="img-responsive"
                  />
                  {/* Box */}
                  <Link to="/Project">
                    <div className="box">
                      <div className="box-content">
                        <div className="box-text">
                          <h2> Project Cargo</h2>
                          <p>
                            {companyname} takes pride in offering comprehensive
                            and reliable solutions for all types of project
                            cargo, regardless of size or complexity. With our
                            wealth of experience, we ensure your projects stay
                            on track, delivering timely and successful outcomes.
                          </p>
                        </div>
                      </div>
                      {/*End box-content */}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* End row*/}
          </div>
        </div>
      </section>
      <section className="w3l-clients" id="clients">
        <div className="main-w3 ">
          <div className="container">
            {/* main-slider */}
            <div className="row">
              <div className="col-lg-6 col-md-6  col-sm-6 hh14-text  margin-bottom">
                <div className="client-color  ">
                  <h3 className="text-center">Mission</h3>
                  <p className="para ">
                    Our mission is to become the premier transport and logistics
                    company in Mumbai, offering our clients unparalleled
                    profitability and competitive advantages. We are committed
                    to achieving this goal through a range of strategic
                    initiatives.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6  col-sm-6 hh14-text  margin-bottom">
                <div className="client-color  ">
                  <h3 className="text-center">Vision</h3>
                  <p className="para ">
                    Our vision is to lead the way in transport and logistics,
                    earning the trust and loyalty of our clients as their
                    preferred partner in Mumbai and beyond.{" "}
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
