import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
        <div className="about-inner ">
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li className="right-side ">
               <Link to="/Home" className>Home <span className="fa fa-angle-right" aria-hidden="true" /></Link >{" "}
                <p />
              </li>
              <li className="active ">Sea Freight</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-content-with-photo-4" id="about">
        <div className="content-with-photo4-block ">
          <div className="container">
            <div className="main-titles-head text-center">
              <h3 className="header-name ">Sea Freight</h3>
              <p className="tiltle-para  "> Services</p>
            </div>
            <div className="cwp4-two row">
              <div className="cwp4-image col-lg-5 col-md-6">
                <img
                  src="assets/images/sea.png"
                  alt="product"
                  className="img-responsive about-me"
                />
              </div>
              <div className="cwp4-text col-lg-7 col-md-6">
                <div className="posivtion-grid">
                  <p className="para ">
                  At {companyname}, we understand that every ocean freight requirement is unique, and that's why we tailor our services to cater to your specific needs.
<br/>
                    Our Full Container Load (FCL) and Less Than Container Load
                    (LCL) services ensure that your shipments are handled with
                    precision and efficiency, whether they require full
                    container capacity or are consolidated with other cargo.
                    <br />
                    For out-of-gauge and break-bulk shipments that may not fit
                    standard containers, our specialized services offer seamless
                    handling for even the most challenging cargo. As a trusted
                    partner, we are well-versed in complying with all cargo
                    regulations, ensuring that your shipments meet all necessary
                    customs and regulatory requirements for smooth and
                    hassle-free transactions.
                  </p>
                </div>
              </div>
              <p className="para mt-4">
              <br /> Safety and security are paramount in our operations, and we
                    go the extra mile to protect your cargo during its journey,
                    providing peace of mind for you and your customers. From
                    pick-up to delivery, our end-to-end services cover all
                    aspects of export and import handling, allowing you to focus
                    on your core business while we take care of the logistics.<br/>
                    With capacity management at the forefront of our operations,
                    we optimize your shipping process by strategically planning
                    and allocating resources to meet your demands efficiently.
                    <br />
                    Our pre-clearance services expedite the customs clearance
                    process, reducing delays and ensuring your cargo moves
                    swiftly through the supply chain.
                    <br />
                    And lastly, our commitment to accuracy and attention to
                    detail ensures that all documentation is delivered on time
                    and without errors, simplifying the logistics process and
                    ensuring smooth operations from start to finish.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
