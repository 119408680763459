import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

     
      <section className="w3l-inner-banner-main">
          <div className="about-inner ">
            <div className="container">        
              <ul className="breadcrumbs-custom-path">
                <li className="right-side "><Link to="/Home" className>Home <span className="fa fa-angle-right" aria-hidden="true" /></Link > <p /></li>
                <li className="active ">Contact</li>
              </ul></div>
          </div>
        </section>
        {/* breadcrumbs //*/}
        <section className="w3l-contact-info-main" id="contact">
          <div className="contact-sec	">
           
            <div className="container">
              <div className="main-titles-head text-center mt-5">
                <h3 className="header-name ">
                  Send Us a Message
                </h3>
                <p className="tiltle-para ">Get in Touch</p>
              </div>
              <div className="row">
                <div className=" col-lg-7 col-md-6 back-color">
                <form
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input className="form-control"
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                      />
                      <span />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input className="form-control"
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input className="form-control"
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input className="form-control"
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                      className="form-control"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                    </div>
                  </div>
                  <div className="submit-w3l-button text-center">
                    <button
                      name="submit"
                      className="btn action-button mt-3"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h1>{result}</h1>
                </div>
                <div className="col-lg-5 col-md-6 cont-details ">
                  <h5 className="contact-para">
                    About Company
                  </h5>
                  <p className="para contact-border">As a dedicated freight company, {companyname} is driven by a passion for excellence in cargo transportation. We specialize in delivering reliable and efficient freight forwarding services, catering to a diverse range of industries and clients.<br/>

With our extensive network and experienced team, we have the expertise to handle various types of cargo, from small packages to oversized and specialized shipments.</p>
                  <address className="mt-3">
                    <p className="para "><span className="fa fa-map" />{companyaddress}</p>
                  </address>
                  <p className="para "><span className="fa fa-envelope" /><a href="mailto:{companyemail}" className>{companyemail}</a></p>
                  <p className="para "><span className="fa fa-phone" /> <a href="tel:{companynumber}">{companynumber}</a></p>
                </div>
              </div>
            </div>
            <div>
            </div>
           
          </div>
          <div className="contact text-left">
          <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60275.69726106052!2d72.9344066!3d19.2287584!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b92abc14627f%3A0x67321c4ebf19795e!2sLake%20City%20Mall!5e0!3m2!1sen!2sin!4v1690354023087!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
            </div>
        </section>

      <Footer />
    </>
  );
}
