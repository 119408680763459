import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
        <div className="about-inner ">
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li className="right-side ">
               <Link to="/Home" className>Home <span className="fa fa-angle-right" aria-hidden="true" /></Link >{" "}
                <p />
              </li>
              <li className="active ">Supply Chain Solutions</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-content-with-photo-4" id="about">
        <div className="content-with-photo4-block ">
          <div className="container">
            <div className="main-titles-head text-center">
              <h3 className="header-name ">Supply Chain Solutions</h3>
              <p className="tiltle-para  "> Services</p>
            </div>
            <div className="cwp4-two row">
              <div className="cwp4-image col-lg-5 col-md-6">
                <img
                  src="assets/images/supply.jpeg"
                  alt="product"
                  className="img-responsive about-me"
                />
              </div>
              <div className="cwp4-text col-lg-7 col-md-6">
                <div className="posivtion-grid">
                  <p className="para ">
                    At {companyname}, we pride ourselves on crafting tailor-made
                    supply chain solutions that precisely match our clients'
                    unique and stringent demands. Our expertise lies in creating
                    seamless connections that bridge the gap between global
                    suppliers and end consumers, ensuring a smooth and efficient
                    flow of goods throughout the supply chain.
                    <br />
                    With a wealth of experience and capabilities, {
                      companyname
                    }{" "}
                    confidently takes on a diverse range of projects. No matter
                    the complexity or scale, we have the knowledge and resources
                    to handle it with utmost professionalism and precision.
                    <br />  Our team of dedicated engineers meticulously design and execute
                supply chain strategies that optimize efficiency, reduce costs,
                and enhance overall performance. By customizing solutions to fit
                each client's specific requirements, we maximize their
                competitive edge in the market.
                <br />
                  </p>
                </div>
              </div>
              <p className="para mt-4">
              
                From sourcing and procurement to transportation and
                distribution, our comprehensive approach encompasses every
                aspect of the supply chain, ensuring a seamless journey for your
                products from origin to destination.
                <br />
                When you partner with {companyname}, you can trust that our
                experienced team will navigate the intricacies of global
                logistics, making the process hassle-free and delivering
                exceptional results for your business. With {companyname} as
                your logistics partner, you gain a strategic advantage, as we
                orchestrate the movement of goods with expertise, reliability,
                and commitment to excellence.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
