import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
        <section className="w3l-footers-20">
        <div className="footers20 ">
          <div className="container">
            <div className="sub-columns row">
              <div className="sub-one-left sub-two-right col-lg-4 col-md-5">
              <Link to="/Home"><img
                  src="assets/images/logo.png"
                  style={{ height: "100px" }}
                ></img></Link>
                <p>As a dedicated freight company, {companyname} is driven by a passion for excellence in cargo transportation. We specialize in delivering reliable and efficient freight forwarding services, catering to a diverse range of industries and clients.<br/>

With our extensive network and experienced team, we have the expertise to handle various types of cargo, from small packages to oversized and specialized shipments. </p>
              </div>
              <div className="sub-two-right col-lg-2 col-md-3 col-sm-6">
                <h6 className>Helpful links</h6>
                <div className="footer-footer-ul">
                  <ul>
                  <li className="">
                      <Link className="" to="/Home"><span className="fa fa-angle-right" aria-hidden="true" />
                        Home
                      </Link>
                    </li>
                    <li className="">
                      <Link className="" to="/About"><span className="fa fa-angle-right" aria-hidden="true" />
                        Company Profile
                      </Link>
                    </li>
                  
                    <li className=" ">
                      <Link className="" to="/Contact"><span className="fa fa-angle-right" aria-hidden="true" />
                        Contact
                      </Link>
                    </li>
                    <li className=" ">
                      <Link className="" to="/Getquote"><span className="fa fa-angle-right" aria-hidden="true" />
                        Getquote
                      </Link>
                    </li>
                    <li className=" ">
                      <Link className="" to="/Privacy"><span className="fa fa-angle-right" aria-hidden="true" />
                        Privacy Policy
                      </Link>
                    </li>
                    <li className=" ">
                      <Link className="" to="/Terms"><span className="fa fa-angle-right" aria-hidden="true" />
                        Terms
                      </Link>
                    </li>
                   
                  </ul>
                </div>
              </div>
              <div className="sub-two-right col-lg-2 col-md-3 col-sm-6">
                <h6 className>Services We Provide</h6>
                <div className="footer-footer-ul">
                  <ul>
                 <li> <Link to="/Sea" ><span className="fa fa-angle-right" aria-hidden="true" />Sea Freight</Link></li>
                 <li><Link to="/Ground" ><span className="fa fa-angle-right" aria-hidden="true" />Ground Freight</Link></li>
                 <li><Link to="/Air" ><span className="fa fa-angle-right" aria-hidden="true" />Air Freight</Link></li>
                 <li><Link to="/Customs" ><span className="fa fa-angle-right" aria-hidden="true" />Customs Clearance</Link></li>
                 <li><Link to="/Supply" ><span className="fa fa-angle-right" aria-hidden="true" />Supply Chain Solutions</Link></li>
                 <li><Link to="/Project" ><span className="fa fa-angle-right" aria-hidden="true" />Project Cargo</Link>   </li> 
                   
                  </ul>
                </div>
              </div>
              <div className="sub-two-right col-lg-4 col-md-4 col-sm-6">
                <h6 className>Contact Us</h6>
                <div className="column2">
                  <div className="to1"><span>E-mail:</span><Link to="mailto:{companyemail}">{companyemail}</Link>
                  </div>
                  <div className="to2"><span>Phone:</span><Link to="tel:{companynumber}">{companynumber}</Link>
                  </div>
                  <div>
                    <p className="contact-para">{companyaddress}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="below-section">
            <div className="container">
              <div className="columns copy-right text-center ">
                <p>© 2023  <Link to="/Home" target="_blank">{companyname}</Link> All rights reserved </p>
                 
              </div>
            </div>
          </div>
        </div></section>
    </>
  );
};

export default Footer;
