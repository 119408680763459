import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
        <div className="about-inner ">
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li className="right-side ">
               <Link to="/Home" className>Home <span className="fa fa-angle-right" aria-hidden="true" /></Link >{" "}
                <p />
              </li>
              <li className="active ">Air Freight</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-content-with-photo-4" id="about">
        <div className="content-with-photo4-block ">
          <div className="container">
            <div className="main-titles-head text-center">
              <h3 className="header-name ">Air Freight</h3>
              <p className="tiltle-para  "> Services</p>
            </div>
            <div className="cwp4-two row">
              <div className="cwp4-image col-lg-5 col-md-6">
                <img
                  src="assets/images/air.jpg"
                  alt="product"
                  className="img-responsive about-me"
                />
              </div>
              <div className="cwp4-text col-lg-7 col-md-6">
                <div className="posivtion-grid">
                  <p className="para ">
                    {companyname} is dedicated to ensuring the swift and
                    reliable delivery of your time-sensitive cargo to its
                    intended destination, maintaining a reputation for
                    punctuality and efficiency that our clients can depend on.
                    <br />
                    <br />
                    Our complete air freight solution offers a comprehensive
                    range of services, both for domestic and international
                    shipping requirements. Whether you need shipments to move
                    between airports or door-to-door delivery services, we have
                    you covered. {companyname}'s extensive network of global
                    partners and strategic alliances enables us to efficiently
                    manage every aspect of the air freight process, guaranteeing
                    seamless and hassle-free logistics for our clients.
                    <br />
                    <br />
                    
                  </p>
                </div>
              </div>
              <p className="para mt-4">From handling charters and oversized cargo to managing
                    various commodity types, we possess the expertise and
                    resources to accommodate diverse shipping needs. Whether
                    it's transporting dangerous goods (DGR) or handling
                    essential ship spares, our team is well-versed in the
                    intricacies of each cargo category, ensuring compliance with
                    safety regulations and industry standards.
                    <br />
                    <br />
                    One of our core competencies lies in providing
                    temperature-controlled solutions for sensitive cargo. We
                    recognize the importance of preserving the integrity of
                    perishable goods and pharmaceutical products during transit,
                    and our specialized handling ensures the optimal conditions
                    are maintained throughout the journey.
                    <br />
                    <br />
                    At {companyname}, we are committed to providing the highest
                    level of customer satisfaction. Our experienced team of
                    logistics professionals works diligently to meet our
                    clients' specific requirements and offer personalized
                    solutions that suit their unique needs. With a focus on
                    attention to detail and a customer-centric approach, we
                    strive to exceed expectations and deliver exceptional
                    results in every air freight endeavor.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
