import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
        <div className="about-inner ">
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li className="right-side ">
               <Link to="/Home" className>Home <span className="fa fa-angle-right" aria-hidden="true" /></Link >{" "}
                <p />
              </li>
              <li className="active ">Customs Clearance</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-content-with-photo-4" id="about">
        <div className="content-with-photo4-block ">
          <div className="container">
            <div className="main-titles-head text-center">
              <h3 className="header-name ">Customs Clearance</h3>
              <p className="tiltle-para  "> Services</p>
            </div>
            <div className="cwp4-two row">
              <div className="cwp4-image col-lg-5 col-md-6">
                <img
                  src="assets/images/customs.jpg"
                  alt="product"
                  className="img-responsive about-me"
                />
              </div>
              <div className="cwp4-text col-lg-7 col-md-6">
                <div className="posivtion-grid">
                  <p className="para ">
                    At our company, customs clearance is a fundamental aspect of
                    our operations, and we take pride in our expertise in
                    handling all types of documentation necessary to ensure
                    compliance with customs regulations.
                    <br />
                    Whether it's import or export shipments, our skilled team is
                    adept at efficiently navigating the customs clearance
                    process, ensuring that your cargo moves swiftly through the
                    necessary formalities. We understand the criticality of
                    timely customs clearance, and our commitment to prompt and
                    accurate processing means that your shipments won't face
                    unnecessary delays at the border.
                    <br />Obtaining legal certificates and permits is often a complex
                task, but with our experienced customs brokers by your side, you
                can be confident that all required documentation will be
                obtained without hassle.
                <br />
                  </p>
                </div>
              </div>
              <p className="para mt-4">
                
                In addition to standard customs clearance services, we offer
                specialized solutions for break-bulk and bond warehousing,
                catering to unique requirements for storage and handling of
                specific goods. For transit bonded cargo, we facilitate seamless
                customs clearance procedures, allowing your cargo to move
                efficiently through the transit zone while meeting all
                regulatory requirements.
                <br />
                To ensure end-to-end logistics excellence, our last mile
                delivery services provide the final touch, delivering your cargo
                directly to its destination, meeting your customers'
                expectations for timely and secure deliveries.
                <br />
                With our comprehensive customs clearance services, you can focus
                on your core business, knowing that our capable team is
                dedicated to ensuring smooth and compliant movement of your
                goods across borders.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
