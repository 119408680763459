import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
        <div className="about-inner ">
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li className="right-side ">
               <Link to="/Home" className>Home <span className="fa fa-angle-right" aria-hidden="true" /></Link >{" "}
                <p />
              </li>
              <li className="active ">Ground Freight</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-content-with-photo-4" id="about">
        <div className="content-with-photo4-block ">
          <div className="container">
            <div className="main-titles-head text-center">
              <h3 className="header-name ">Ground Freight</h3>
              <p className="tiltle-para  "> Services</p>
            </div>
            <div className="cwp4-two row">
              <div className="cwp4-image col-lg-5 col-md-6">
                <img
                  src="assets/images/road.webp"
                  alt="product"
                  className="img-responsive about-me"
                />
              </div>
              <div className="cwp4-text col-lg-7 col-md-6">
                <div className="posivtion-grid">
                  <p className="para ">
                    At our company, we understand that your specific
                    requirements are unique and essential to your business. Our
                    top priority is to ensure that we meet those requirements
                    precisely by providing the right transportation solutions
                    through our extensive network.
                    <br />
                    Whether you need to transport general goods, specialized
                    equipment, or delicate cargo, our expertise covers a wide
                    range of commodities, ensuring that we have the suitable
                    transport options to match your needs. With dedicated
                    delivery vehicles at your service, you can rely on us to
                    provide prompt and personalized transportation solutions,
                    tailored to your schedule and preferences.
                    <br /> We offer secure warehousing and storage facilities to
                accommodate your inventory and ensure the smooth flow of goods
                for both inbound and outbound operations.
                <br />
                  </p>
                </div>
              </div>
              <p className="para mt-4">
               
                Navigating customs and formalities can be challenging, but with
                our expert team of customs brokers, you can rest assured that
                all necessary paperwork and compliance procedures are handled
                efficiently and accurately.
                <br />
                Our commitment to providing comprehensive logistics solutions
                means that you can focus on your core business while we take
                care of the transportation, warehousing, and customs processes
                seamlessly and professionally. Your peace of mind and
                satisfaction are our ultimate goals.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
