import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
          <div className="about-inner ">
            <div className="container">        
              <ul className="breadcrumbs-custom-path">
                <li className="right-side "><Link to="/Home" className>Home <span className="fa fa-angle-right" aria-hidden="true" /></Link > <p /></li>
                <li className="active ">About Us</li>
              </ul></div>
          </div>
        </section>
        <section className="w3l-teams-15">
        <div className="team-single-main ">
          <div className="container">
            <div className=" grid grid-column-2 row">
              <div
                className="column1 image-back col-lg-6 header11"
                data-selector="header11"
              >
                <img
                  src="assets/images/4.jpg"
                  alt=""
                  className="img-responsive"
                />
              </div>
              <div className="column2 image-text col-lg-6">
                <h3 className="team-head ">Who We Are..</h3>
                <p className="para  text ">
                  As a dedicated freight company, {companyname} is driven by a
                  passion for excellence in cargo transportation. We specialize
                  in delivering reliable and efficient freight forwarding
                  services, catering to a diverse range of industries and
                  clients.
                  <br />
                  With our extensive network and experienced team, we have the
                  expertise to handle various types of cargo, from small
                  packages to oversized and specialized shipments. Our
                  commitment to customer satisfaction means that we go the extra
                  mile to ensure that each consignment reaches its destination
                  on time and in pristine condition.
                  <br />{" "}
                </p>
              
              </div>
              <p className="para  text mt-4">At {companyname}, we understand that every shipment is unique, and that's why we offer customized solutions tailored to meet our clients' specific requirements. From air and ocean freight to road transport and customs clearance, we provide a comprehensive suite of services to streamline the supply chain and optimize the movement of goods.<br/>

As a freight company, we take great pride in our ability to tackle complex logistics challenges with efficiency and professionalism. Our team of experts is well-versed in handling all necessary documentation, permits, and regulations, ensuring a smooth and hassle-free shipping experience for our clients.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-clients" id="clients">
        <div className="main-w3 ">
          <div className="container">
            {/* main-slider */}
            <div className="row">
              <div className="col-lg-6 col-md-6  col-sm-6 hh14-text  margin-bottom">
                <div className="client-color  ">
                  <h3 className="text-center">Mission</h3>
                  <p className="para ">
                    Our mission is to become the premier transport and logistics
                    company in Mumbai, offering our clients unparalleled
                    profitability and competitive advantages. We are committed
                    to achieving this goal through a range of strategic
                    initiatives.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6  col-sm-6 hh14-text  margin-bottom">
                <div className="client-color  ">
                  <h3 className="text-center">Vision</h3>
                  <p className="para ">
                    Our vision is to lead the way in transport and logistics,
                    earning the trust and loyalty of our clients as their
                    preferred partner in Mumbai and beyond.{" "}
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
