import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
        <div className="about-inner ">
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li className="right-side ">
               <Link to="/Home" className>Home <span className="fa fa-angle-right" aria-hidden="true" /></Link >{" "}
                <p />
              </li>
              <li className="active ">Project Cargo</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-content-with-photo-4" id="about">
        <div className="content-with-photo4-block ">
          <div className="container">
            <div className="main-titles-head text-center">
              <h3 className="header-name ">Project Cargo</h3>
              <p className="tiltle-para  "> Services</p>
            </div>
            <div className="cwp4-two row">
              <div className="cwp4-image col-lg-5 col-md-6">
                <img
                  src="assets/images/project.jpg"
                  alt="product"
                  className="img-responsive about-me"
                />
              </div>
              <div className="cwp4-text col-lg-7 col-md-6">
                <div className="posivtion-grid">
                  <p className="para ">
                    {companyname} takes pride in offering comprehensive and
                    reliable solutions for all types of project cargo,
                    regardless of size or complexity. With our wealth of
                    experience, we ensure your projects stay on track,
                    delivering timely and successful outcomes.
                    <br />
                    Our dedicated team is committed to getting the job done
                    efficiently and effectively. We prioritize understanding the
                    unique requirements of each client and project, enabling us
                    to tailor the perfect logistics solution that meets your
                    specific needs.
                    <br />As a full-service logistics company, we handle every aspect of
                your cargo's journey, from meticulous planning to smooth
                execution. Our expertise includes coordinating heavy lift
                machinery, conducting route inspections, facilitating customs
                clearance, conducting feasibility inspections, and arranging
                crane hire, among other essential services.
                <br />
                  </p>
                </div>
              </div>
              <p className="para mt-4">
                
                At {companyname}, we understand the critical nature of
                project cargo movements and the importance of meeting deadlines.
                Our reliable and agile approach ensures your cargo is managed
                with utmost care and precision, delivering peace of mind and
                successful project outcomes.
                <br />
                With {companyname} as your logistics partner, you can rest
                assured that your project cargo is in capable hands, and our
                commitment to excellence ensures seamless coordination and
                flawless execution, allowing you to focus on other vital aspects
                of your project's success.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
