import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from "./pages/Contact";
import Sea from "./pages/Sea";
import Customs from "./pages/Customs";
import Air from "./pages/Air";
import Ground from "./pages/Ground";
import Project from "./pages/Project";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Supply from "./pages/Supply";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";




import Signin from "./admin/Signin";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Sea" element={<Sea/>} />
          <Route path="/Customs" element={<Customs />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Ground" element={<Ground />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Supply" element={<Supply />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />

        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));